import { useRuntimeConfig } from '#imports';
import * as Sentry from '@sentry/nuxt';

const config = useRuntimeConfig();
//if (import.meta.dev) return;
Sentry.init({
  // app: nuxtApp.vueApp,
  dsn: config.public.sentry.publicDSN,
  ignoreErrors: [
    'fb_xd_fragment',
    'BrowserUARetrieverFromJSEvent',
    'FBNavLargestContentfulPaint',
    'FBNavFirstContentfulPaint',
  ],
  // trackComponents: true,
  integrations: [
    // ... your integrations
  ],
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/mappr\.com/,
    'https://api.mappr.com',
  ],
  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event) => {
    const { headers } = event?.request || {};

    if (headers?.['User-Agent'] === 'HeadlessChrome') {
      console.log('Dropping error event due to user-agent');
      return null;
    }

    return event;
  },
});
